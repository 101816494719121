import { FEATURE } from 'api/src/common/enums'

import { routes, navigate } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import OnboardingIntegrations from 'src/components/Onboarding/OnboardingIntegrations'
import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader'
import useFeature from 'src/lib/hooks/useFeature'

const OnboardingIntegrationsPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  // Navigate away when permission denied
  if (!onboardingFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata
        title="Setup - Integrations"
        description="Setup - Integrations page"
      />
      <div className="relative">
        <OnboardingPageHeader
          currentPage={{
            name: 'Integrations',
            url: routes.onboardingIntegrations(),
          }}
        />
        <OnboardingIntegrations />
      </div>
    </>
  )
}

export default OnboardingIntegrationsPage
